import React, { useRef, useState } from 'react';
import style from './availabilityCard.module.css';
import CloseIcon from "../../assets/images/close-cross.png";

function AvailabilityCard({ center, memoizedAppointments, topLocationsStatus, hasTopLocations,hasError, onClick = () =>
  console.warn("Appointment Selector does not have an onClick bound"), selectedLocation, selectedAppointmentSlot, windowWidth, hasAppointments, appointmentType, searchAddress }) {
    const options = useRef([]); 
    const [noSlots, setNoSlots] = useState(false);

    console.log("")
    if (topLocationsStatus === "idle" && !hasTopLocations && searchAddress)
      return (
        <div className="bg-white rounded-lg my-4 p-4  media" style={{ display: "flex", justifyContent: "center", width: windowWidth < 768 ? "93%" :"100%", padding: "35px 0px 35px 0px", marginBottom: "0px" , marginLeft: "0rem", alignItems: "baseline"}}>
          <div className="media_left media_item" style={{ marginLeft: windowWidth < 1024 ? "15px" : "10px"}}>
            <img src={CloseIcon} />
          </div>
          <div className="media_right media_item" style={{ marginRight: windowWidth < 1024 ? "18px" : "0px"}}>
            <p>
              Sorry, we do not have any centers within 50 miles of your location.
              Please try searching another location or{" "}
              <a href="mailto:info@heartandpaw.com">email</a> us if you have any
              questions.
            </p>
          </div>
        </div>
      );

    else if(!hasAppointments && appointmentType === "care" && searchAddress && topLocationsStatus === "idle" ) {

      return (
      <div className="bg-white rounded-lg my-4 p-4  media" style={{ display: "flex", justifyContent: "center", width: "96%", padding: "35px 0px 35px 0px" ,alignItems: "baseline"}}>
      <div className="media_left media_item">
        <img src={CloseIcon} />
      </div>
      <div className="media_right media_item">
        <p>We could not find any available appointments that matched your search criteria. Try changing your search location.
        </p>
      </div>
    </div>
      )
    }


  
    // <div className={style.card}>
    //   <img src={center.imageUrl} alt={center.name} className={style.cardImage} />
    //   <div className={style.cardContent}>
    //     <div className={style.cardHeader}>
    //       <h2 className={style.centerName}>{center.name}</h2>
    //       <span className={style.centerDistance}>{center.distance}</span>
    //     </div>
    //     <p className={style.centerAddress}>{center.address}</p>
    //     <div className={style.timeSlots}>
    //       {center.times.map((time, index) => (
    //         <button 
    //           key={index} 
    //           className={`${style.timeSlot} ${selectedTime === time ? 'selected' : ''}`} 
    //           onClick={() => setSelectedTime(time)}
    //         >
    //           {time}
    //         </button>
    //       ))}
    //     </div>
    //   </div>
    // </div>
    return (
    <>
    {memoizedAppointments.map((location) => (
    
      
      <div
        key={location.id}
        className={`${style.cardParent} bg-white rounded-xl p-4 w-full md:w-1/3  mr-3 mb-3 flex flex-col`}
      >
        <img
          src={location?.img?.link || 'https://heartandpaw.com/wp-content/uploads/2024/03/animal-care-center-exterior.jpg'}
          alt={location?.displayName}
          className={`${style.imgFix} w-full h-32 object-cover rounded-xl`}
        />
        <div className="mt-4">
          <div className={`${style.headingParent}`}>
            <h3 className={`${style.centerName} text-lg font-semibold`}>{location?.displayName}</h3>
            <p className="text-gray-600 text-right" style={{ lineHeight: "25px" }}>
              {location?.wp?.distanceText}
            </p>
          </div>
          <p className={`${style.centerAddress} text-gray-600`}>
            {`${location?.wp?.meta?.locations_meta_address?.street }${
                location?.wp?.meta?.locations_meta_address?.street2  ? " " + location?.wp?.meta?.locations_meta_address?.street2  : ""
              } ${location?.wp?.meta?.locations_meta_address?.city}, ${location?.wp?.meta?.locations_meta_address?.state} ${location?.wp?.meta?.locations_meta_address?.zip}`}
          </p>
          {location?.isUrgentVetCare == true && Object.keys(location.availableSlots).length > 0 ? <div><p className={`${style.urgentCare}`}><b>We accept urgent care visits here. Fees apply for urgent care and wait times are not guaranteed.</b></p></div> 
          : <div className={`${style.urgentCareHidden}`}><p><b>We accept urgent care visits here. Fees apply for urgent care and wait times are not guaranteed.</b></p></div>}
          <div className={`${style.slotsFix}`}>
        <div className={`${style.timeSlots} mt-2 grid grid-cols-3`}>
          {Object.keys(location.availableSlots).map((date) => {
            const slots = location.availableSlots[date].slots;

           
            return slots.length > 0 ? (
              slots.map((slot) => (
                <>
                <button
                  key={slot.key || selectedAppointmentSlot?.key}
                  ref={(el) => options.current.push(el)}
                  role="radio"
                  aria-checked={ selectedAppointmentSlot?.key === slot.key}
                  onClick={() => {
                    // TODO: This could be made smoother
                      
                      let apptData = { ...slot };
                      onClick({ ...apptData }, {...location.wp, timezone: location.timezone });
                    
                  }}
                  className={`${style.timeCount} text-center py-2 px-4 rounded-md focus:outline-none  ${
                    selectedAppointmentSlot?.key === slot.key
                      ? style.customSelect 
                      : "bg-gray-200 border-gray-200"
                  }`}
                  // className={`${style.timeCount} text-center py-2 px-4 bg-gray-200 rounded-md hover:text-black`}
                >
                  {slot.regularTime}
                </button>
               </>
              ))
            ) : (
              <>
                <p key={date} className="text-grey-500 col-span-3 text-left">
                  <b>
                    Sorry! We could not find any available appointments.
                  </b>
                </p>
                {/* <p>
                  <b>
                    Please try expanding your search or call our center at{" "}
                    <a href={"tel:" + location?.wp?.meta?.locations_meta_phone }>{location?.wp?.meta?.locations_meta_phone }</a>.
                  </b>
                </p> */}
              </>
            );
          })}
        </div>
      </div>
        </div>
      </div>
      
    ))}
      </>
  ); // return before if not work uncomment this

    {/* <div className={`${style.cardParent} bg-white rounded-xl p-4 w-full md:w-1/3  mr-3 mb-3 flex flex-col`}>
    <img src={center.imageUrl} alt={center.name} className={`${style.imgFix} w-full h-32 object-cover rounded-xl`} />
    <div className="mt-4 flex-grow">
      <div className={`${style.headingParent}`}>
      <h3 className={`${style.centerName} text-lg font-semibold`}>{center.name}</h3>
      <p className="text-gray-600" style={{ lineHeight: "25px"}}>{center.distance}</p>
      </div>
      <p className={`${style.centerAddress} text-gray-600`}>{center.address}</p>
      <div className={`${style.slotsFix}`}>
      <div className={`${style.timeSlots} mt-2 grid grid-cols-3`}>
        {center.times.map((time, index) => (
          <button key={index} className={`${style.timeCount} text-center py-2 px-4 bg-gray-200 rounded-md hover:bg-green-500 hover:text-white`}>
            {time}
          </button>
        ))}
      </div>
      </div>
    </div>
  </div> */}


//   return (
//     // <div className={style.card}>
//     //   <img src={center.imageUrl} alt="Center"  className={style.cardImage} />
//     //   <div  className={style.cardContent}>
//     //     <h2>{center.name}</h2>
//     //     <p>{center.address}</p>
//     //     <p>{center.distance}</p>
//     //     <div  className={style.timeSlots}>
//     //       {center.times.map((time, index) => (
//     //         <button key={index} className={style.timeSlot}>{time}</button>
//     //       ))}
//     //     </div>
//     //   </div>
//     // </div>

// <div className={style.card}>
// <img src={center.imageUrl} alt={center.name}  className={style.cardImage} />
// <div  className={style.cardContent}>
//   <div className={style.cardHeader}>
//     <h2 className={style.centerName}>{center.name}</h2>
//     <span className={style.centerDistance}>{center.distance}</span>
//   </div>
//   <p className="center-address">{center.address}</p>
//   <div className={style.timeSlots}>
//     {center.times.map((time, index) => (
//       <button key={index} className={style.timeSlot}>{time}</button>
//     ))}
//   </div>
// </div>
// </div>
//   );
}

export default AvailabilityCard;