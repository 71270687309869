// next disabled code 

import React, { useState, Fragment, useEffect, useRef, useMemo } from "react";
import style from "./todaysAppointment.module.css";
import AvailabilityCard from "./availabilityCard";
import routes from "../../routes";
import { useGlobalStateContext } from "../../contexts/GlobalContext";
import { Link } from "react-router-dom/cjs/react-router-dom";
import currLocationImg from "../../assets/images/useCurrentLoc.svg"
import { paramsOnLoad, saveToSession } from "../../utils";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useTodaysLocations from "../../hooks/useTodaysLocations.js";
import LocationIcon from "../../assets/icons/locationIcon.svg";
import axios from "axios";
import { API_DOMAIN, SESSION_STORAGE_KEYS, AZURE_DOMAIN } from "../../const.js";
import cloneDeep from "lodash/cloneDeep";
import useOutsideClick from "../../hooks/useOutsideClick.js";
import { sortBy } from "lodash";

export default function TodaysAppointment() {


  const {
    state,
    setAppointmentType,
    setIsTodaysAppointment,
    setLocations,
    setAppointmentSlot,
    setAppointmentReason,
    setCareAndTypesData,

    // setPreferredProviders,
  } = useGlobalStateContext();
  const { email, searchAddress,appointmentType, selectedAppointmentSlot,selectedLocation, appointmentTypeId } = state;
  let resonforVisit;
  const [errorState, setErrorState] = useState({ type: false });
  // const [errorState, setErrorState] = useState({ type: false });
  const isTodaysAppointment = paramsOnLoad.get("isToday");
  const postalCode = paramsOnLoad.get("searchAddress");
  const [searchLat, setSearchLat] = useState("");
  const [searchLong, setSearchLong] = useState("");
  const [apptType, setApptType] = useState("Sick");
  const [error, setError] = useState(null);
  const [loading, topLocations, topLocationsStatus] = useTodaysLocations(
    searchAddress,
    appointmentType
  );
   const [appointmentLocations, setAppointmentLocations] = useState([]);
   const sortByOptions = [
    { name: "Closest Location", value: "closest" },
    { name: "Earliest Appointment", value: "soonest" },
  ];

  console.log("topLocations",topLocations)
  const [appointmentLocationsStatus, setAppointmentLocationStatus] =
    useState("idle");
    const [isAppointmentSlotLoading, setIsAppointmentSlotLoading] =
    useState(false);
  const ongoingFetch = useRef(null);
  const getUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const location = state.selectedLocation;
  const [selectedSort, setSelectedSort] = useState(sortByOptions[0]);
  const [providers, setProviders] = useState([]);
  const [preferredProvider, setPreferredProvider] = useState(null);
  const ref = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const slotLocationSelected = selectedAppointmentSlot && selectedLocation && Object.keys(selectedAppointmentSlot).length > 0 && 
  Object.keys(selectedLocation).length > 0;;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "us" },
    },
    debounce: 100,
  });


  useOutsideClick(ref, () => {
    clearSuggestions();
  });

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if(postalCode){
      setLocations({ searchAddress: postalCode});
      setValue(searchAddress, false);
    }
  },[postalCode])



  useEffect(() => {
    if(!searchAddress && !postalCode){
    getCurrentLocationHandler();
    }
  },[searchAddress])


  useEffect(() =>{
    axios
              .get(`${API_DOMAIN}/v1/appointment-types`)
              //  .then((r) => r.json())
              .then((data) => {
                const payload = data.data.reduce(
                  (acc, curr) => {
                    const splitNames = curr.name.split(" - ");
                    if (splitNames.length === 2) {
                      acc.careTypes.push({
                        name: splitNames[1],
                        value: curr.id,
                        duration: curr.duration,
                      });
                    } else {
                      // this isn't included in the current booking tool
                      // which is why we skip it completely
                      if (curr.name === "Stay") return acc;

                      acc.appointmentTypes.push({
                        name: curr.name,
                        value: curr.name.toLowerCase(),
                        vetspireId: curr.id,
                        duration: curr.duration,
                      });
                    }

                    return acc;
                  },
                  {
                    careTypes: [],
                    appointmentTypes: [
                      { name: "Care", value: "care", vetspireId: "" },
                    ],
                  }
                );
                console.log("API payload", payload);

                // We're setting these separately so it's simpler to grab them out later piecemeal
                // because we can end up needing the careType w/o appt types
                const sortedCareTypes = sortBy(payload.careTypes, ["name"]);
                saveToSession(
                  SESSION_STORAGE_KEYS.CARE_REASONS,
                  sortedCareTypes
                );
                saveToSession(
                  SESSION_STORAGE_KEYS.APPOINTMENT_TYPES,
                  payload.appointmentTypes
                );
                setCareAndTypesData({
                  careTypes: sortedCareTypes,
                  appointmentTypes: payload.appointmentTypes,
                });
              })
  },[])
  
  useEffect(() => {
    if (isTodaysAppointment) {
      setIsTodaysAppointment({ isTodaysAppointment: true });
    }
  }, [])

  useEffect(() => {
    setAppointmentType({
      type: "care",
      id: apptType === "Sick" ? "271" : "270",
    });
  }, [apptType])

  useEffect(() => {
    setValue(searchAddress, false);
  }, [searchAddress, setValue]);
  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };


  // const selectedCareReason =
  //   state.careTypes.find((type) => {
  //     console.log(
  //       type.value === state.appointmentTypeId,
  //       type.value,
  //       state.appointmentTypeId
  //     );
  //     return type.value === state.appointmentTypeId;
  //   }) || null;
    


    // useEffect(() => {
    //   if (selectedCareReason) {
    //     if (selectedCareReason?.name === "Wellness") {
    //       resonforVisit = "Care-Wellness";
    //       setAppointmentReason({ appointmentReason: resonforVisit })
    //     }
    
    //     if (selectedCareReason?.name === "Sick") {
    //       resonforVisit = "Care-Sick";
    //       setAppointmentReason({ appointmentReason: resonforVisit })
    //     }
    //   }
    // },[appointmentTypeId,appointmentType, searchAddress])
    

  const getCurrentLocationHandler = () => {
    if (navigator?.geolocation) {
      navigator?.geolocation.getCurrentPosition(showPosition,showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };


  function showPosition(position) {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      if (latitude && longitude) {
        setSearchLat(latitude);
        setSearchLong(longitude);
      }

      let geocoder;
      geocoder = new window.google.maps.Geocoder();
      let latlng = new window.google.maps.LatLng(latitude, longitude);

      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            console.log(
              "results[0].address_components",
              results[0].address_components
            );

            const add = results[0].formatted_address;
            const value = add.split(",");
            const count = value.length;
            const city = value[count - 3];

            for (let i = 0; i < results[0].address_components.length; i++) {
              let postalCode = results[0].address_components[i].types[0];

              if (postalCode === "postal_code") {
                let postal_code = results[0].address_components[i]["long_name"];

                if (postal_code) {
                  setLocations({ searchAddress: postal_code });
                }
              }
            }

            /*
                          var add = results[0].formatted_address;
                          var value = add.split(",");
            
                          count = value.length;
                          country = value[count - 1];
                          state = value[count - 2];
                          city = value[count - 3];
                          x.innerHTML = "city name is: " + city;
                          */
          } else {
            //x.innerHTML = "address not found";
            console.log("address not found");
          }
        } else {
          //x.innerHTML = "Geocoder failed due to: " + status;
          console.log("Geocoder failed due to");
        }
      });
    });    
  }
  
  function showError(error) {
    switch(error.code) {
      case error.PERMISSION_DENIED:
        console.log( "User denied the request for Geolocation.")
        setLocations({ searchAddress: "Philadelphia" });
        setValue(searchAddress, false);
        break;
      case error.POSITION_UNAVAILABLE:
        console.log( "Location information is unavailable.")
        setLocations({ searchAddress: "Philadelphia" });
        setValue(searchAddress, false);
        break;
      case error.TIMEOUT:
         console.log("The request to get user location timed out.")
         setLocations({ searchAddress: "Philadelphia" });
         setValue(searchAddress, false);
        break;
      case error.UNKNOWN_ERROR:
        console.log( "An unknown error occurred.")
        setLocations({ searchAddress: "Philadelphia" });
        setValue(searchAddress, false);
        break;
    }
  }

  // useEffect(() => {
  //   // console.log("state->>", state);
  //   setAppointmentSlot({ location: {}, appointment: {} });
  //   // if (state === "Idle") {
  //   //   setIsOpen(true);
  //   // }
  // }, [state]);




  //   const [selectedCity, setSelectedCity] = useState(cities[0].value);
  //   const [sortZipCode, setSortZipCode] = useState('');

  // const handleCityChange = (event) => {
  //   setSelectedCity(event.target.value);
  // };

  // const handleSortZipCodeChange = (event) => {
  //   setSortZipCode(event.target.value);
  // };

  const handleSelect =
    ({ description }) =>
    () => {
      setValue(description, false);
      clearSuggestions();

      setLocations({ searchAddress: description });
      setAppointmentLocations([]);

      // enabled lat long for calculating walk-in distance
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setSearchLat(lat);
        setSearchLong(lng);

        setLocations({ searchAddress: description });
      });
    };

    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          description,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
  
        console.log("data", suggestion);
  
        const descriptionLength = description.length;
  
        return (
          <li
            className={`${style.customeMedia}`}
            key={place_id}
            onClick={handleSelect(suggestion)}
            style={{ cursor: "pointer" }}
          >
            <div className={`${style.customeMediaImg}`} style={{ paddingTop: "1px" }}>
              <img src={LocationIcon} />
            </div>
            <div className={`${style.customeMediaBody}`}>
              <div className="body_part">
                <p style={{ paddingBottom: "0px", fontSize: "13px" }}>
                  {main_text} &nbsp;
                  <span
                    title={description}
                    style={{
                      fontSize: "12px",
                      color: "#a2a2a2",
                      lineHeight: "17px",
                      paddingRight: "5px",
                    }}
                  >
                    {descriptionLength <= 40
                      ? description
                      : description?.substr(0, 40) + "..."}
                  </span>
                </p>
              </div>
            </div>
          </li>
        );
      });
    
      function getAppointments(
      apptType,
      apptTypeId = 236,
      locations = 567,
     // startDate,
     // endDate,
      //numberOfPets,
      // species = false,
      // price = null,
      // client_id = undefined,
      // patient_id = undefined,
      // latestWeight
    ) {
      //console.log("species is....", species);
      //console.log(72, "top locations", locations);
  
      // const locationId = locations
      //   .map((m) => m.meta.locations_meta_vetspire_id)
      //   .join(",");
      // const americaTimeZone = 'America/Los_Angeles';
      // //https://devapis.heartandpaw.com/api/v1/?apptTypeId=236&timeZone=Asia/Calcutta
      // //https://devapis.heartandpaw.com/api/v1/todays-appointments?apptTypeId=270&timeZone=Asia/Calcutta&excludeLocationId=267&locationId=267
      // let endpoint = `${API_DOMAIN}/v1/todays-appointments?apptTypeId=${apptTypeId}&timeZone=${americaTimeZone}&locationId=${locationId}`;
  
      const devLoc = [268,267,152];
      const devLocId =  devLoc.join(",");
      const locationId = locations
        .map((m) => m.meta.locations_meta_vetspire_id)
        .join(",");
      const americaTimeZone = 'America/Los_Angeles';
      //https://devapis.heartandpaw.com/api/v1/?apptTypeId=236&timeZone=Asia/Calcutta
      //https://devapis.heartandpaw.com/api/v1/todays-appointments?apptTypeId=270&timeZone=Asia/Calcutta&excludeLocationId=267&locationId=267
      let endpoint = `${API_DOMAIN}/v1/todays-appointments?apptTypeId=${appointmentTypeId}&timeZone=${americaTimeZone}&locationId=${AZURE_DOMAIN == ('https://devapis.heartandpaw.com' || 'https://uatapis.heartandpaw.com') ? devLocId :locationId}`;
      // if (apptType === "style" && price) {
      //   endpoint += `&pricing=${price}&client_id=${client_id}&weight=${latestWeight}&patient_id=${patient_id}`;
      // }
      // if (species) {
      //   endpoint = `${endpoint}&species=${species}`;
      // }
      return axios.get(endpoint).then((res) => res.data);
    }

      async function getAppointmentTimes(locations) {
        //console.log(locations);
        //console.log("get appointment times for....", locations);
        try {
          // let availabilitySpecies = false;
          // if (state.appointmentType === "style") {
          //   availabilitySpecies = state.patients[state.selectedPatients[0]].species;
          // } else if (state.appointmentType === "care") {
          //   const isExotic = state.selectedPatients.reduce((acc, curr) => {
          //     const currentSpecies = state.patients[curr].species?.toLowerCase();
          //     return ["canine", "feline"].indexOf(currentSpecies) < 0 || acc;
          //   }, false);
          //   //console.log("is exotic");
          //   availabilitySpecies = isExotic ? "exotic" : false;
          // }
          setAppointmentLocationStatus("loading");
    
          //setProviders([]);
    
          //setAppointmentLocations([]);
    
          // const selectedPet = state.patients[state.selectedPatients[0]];
          // let latestWeight = selectedPet?.latestWeight;
          // const unit = selectedPet?.weightUnit?.toUpperCase();
          // if (unit == "KG") {
          //   latestWeight = +selectedPet?.latestWeight * 2.205;
          // }
    
          // const startApptDate = new Date(sliceApptDates.at());
          // const endApptDate = new Date(sliceApptDates?.at(-1));
    
          // const start_date = formatDate({
          //   year: startApptDate.getFullYear(),
          //   month: startApptDate.getMonth() + 1,
          //   day: startApptDate.getDate(),
          // });
          // const end_date = formatDate({
          //   year: endApptDate.getFullYear(),
          //   month: endApptDate.getMonth() + 1,
          //   day: endApptDate.getDate(),
          // });
    
          const currentFetch = getAppointments(
            state.appointmentType,
            state.appointmentTypeId,
            locations,
            // start_date,
            // end_date,
            // state.selectedPatients.length || 1,
            // availabilitySpecies,
            // state.serviceOption?.unitPrice,
            // state.id,
            // state.selectedPatients[0],
            // latestWeight
          );
          ongoingFetch.current = currentFetch;
    
          setIsAppointmentSlotLoading(true);
          // setAppointmentLocations([]);
    
          const json = await new Promise((res, rej) => {
            currentFetch.then((data) => {
            console.log("data",data);
              //console.log("data from api", data);
              //console.log("samesies?", currentFetch === ongoingFetch.current);
              if (currentFetch === ongoingFetch.current) {
                //console.log("they were the same");
                ongoingFetch.current = null;
                
                res(data);
                setIsAppointmentSlotLoading(false);
              } else {
                rej("Not current fetch anymore!");
              }
            });
          }).catch((err) => {
            console.warn(err);
          });
  //console.log("json", json);
    
          // todo: clean up the error handling here ... because it'll all get
          // eaten up by the dupe fetch issue
          if (json) {
            //console.log(`JSON IS`, json);
            const mappedLocations = json?.appointments?.map((loc, idx) => {
              const wpLoc = locations.find(
                (l) => l.meta.locations_meta_vetspire_id === loc.id
              );
              loc.wp = wpLoc; //locations[idx];
              return loc;
            });
            // setPreferredProvider(null);
    
            // const providersData = json?.providers?.length
            //   ? [{ name: "Any", value: null }, ...json.providers, ...providers]
            //   : [];
    
            // const uniqueProviders = [
            //   ...new Map(providersData?.map((item) => [item.name, item]))?.values(),
            // ];
    
            // setProviders(uniqueProviders);
    
            let r = mappedLocations;
    
            // if (selectedCenterIdLoadMore) {
            //   const findAppt = mappedLocations?.find(
            //     (t) => t.id == selectedCenterIdLoadMore
            //   );
            //   const fr = appointmentLocations?.map((apt) => {
            //     if (apt.id == selectedCenterIdLoadMore)
            //       return {
            //         ...apt,
            //         availableSlots: {
            //           ...apt.availableSlots,
            //           ...findAppt.availableSlots,
            //         },
            //       };
            //     else return apt;
            //   });
    
            //   r = fr;
            // }
    
            // const getCenterId = r?.map((center) => center.id);
            const obj = {};
            // getCenterId?.forEach((elem, i) => {
            //   obj[elem] = 1;
            //   if (currentMapLocationPage[elem]) {
            //     obj[elem] = currentMapLocationPage[elem];
            //   } else {
            //     obj[elem] = 1;
            //   }
            // });
    
            // obj.apptData = currentMapLocationPage?.apptData
            //   ? currentMapLocationPage?.apptData
            //   : [];
    
            mappedLocations?.forEach((appt) => {
              const slotArr = Object?.values(appt?.availableSlots);
              const isAvailableAppt = slotArr?.some((el) => el.slots.length > 0);
    
              if (obj[appt?.id]) {
                if (isAvailableAppt) {
                  obj.apptData.push(appt?.id);
                } else {
                  const index = obj.apptData.indexOf(appt.id);
                  if (index !== -1) {
                    obj.apptData.splice(index, 1);
                  }
                }
              }
            });
            //const locationsIdArr = [...new Set(obj?.apptData)];
            //const updatedPagination = { ...obj, apptData: locationsIdArr };
           // setCurrentMapLocationPage(updatedPagination);
            setAppointmentLocations(r);
            setAppointmentLocationStatus("ready");
          }
        } catch (err) {
          console.error("the error is....");
          console.error(err);
          //setPreferredProvider(null);
          //setProviders([]);
          //setCurrentMapLocationPage({});
          //setselectedCenterIdLoadMore(null);
          setAppointmentLocations([]);
          setAppointmentLocationStatus("error");
        }
      }

      const hasTopLocations = topLocations?.length;
      
      useEffect(() => {
        if (topLocations?.length) {
          // const currentLoc = topLocations?.filter(
          //   (loc) => loc.meta.locations_meta_vetspire_id == selectedCenterIdLoadMore
          // );
          // const fetchLocation = selectedCenterIdLoadMore
          //   ? currentLoc
          //   : topLocations;
          getAppointmentTimes(topLocations);
        } else {
          setAppointmentLocations([]);
          setAppointmentLocationStatus("cant-search");
          //setselectedCenterIdLoadMore(null);
          //setPreferredProvider(null);
          //setProviders([]);
          //setCurrentMapLocationPage({});
        }
      }, [
        searchAddress,
        topLocations,
        // formatDate(state.appointmentRange.from),
        // formatDate(state.appointmentRange.to),
        // loadMoreCount,
        // selectedCenterIdLoadMore,
        appointmentTypeId
      ]);

      const memoizedAppointments = useMemo(() => {
        let start = cloneDeep(appointmentLocations)
          // .filter((a) => Object.keys(a.availableSlots).length > 0)
          .map((l) => {
            // skip if it isn't selected
            if (!preferredProvider || preferredProvider?.value === null) return l;
              console.log(29, preferredProvider, l);
            if (appointmentType == "care") {
              const dates = Object.keys(l.availableSlots);
    
              //console.log(dates, l.availableSlots);
    
              dates.forEach((DATE_KEY) => {
                //console.log(l.name, DATE_KEY, l.availableSlots[DATE_KEY]);
    
                l.availableSlots[DATE_KEY].slots = l.availableSlots[
                  DATE_KEY]
                .slots.filter(
                  (slot) => slot.availableAppointments[preferredProvider.id]
                );
    
                if (!l.availableSlots[DATE_KEY].slots.length)
                  delete l.availableSlots[DATE_KEY];
              });
            }
    
            return l;
          });

          // //console.log("starting appts", start);
  
      const sortByDistance = (a, b) => {
        if (a.wp.distance < b.wp.distance) return -1;
        else if (a.wp.distance > b.wp.distance) return 1;
        return 0;
      };
      if (selectedSort.value === "closest") return start.sort(sortByDistance);
  
      return start.sort((a, b) => {
        const aFirst = Object.keys(a.availableSlots).sort()[0];
        const bFirst = Object.keys(b.availableSlots).sort()[0];
  
        if (aFirst < bFirst) return -1;
        else if (aFirst > bFirst) return 1;
        else {
          return sortByDistance(a, b);
        }
      });
    }, [preferredProvider, appointmentLocations, selectedSort]);

    function appointmentslot() {
      // removed condition todaydate === b[0]
      // for (let i in memoizedAppointments) {
      //   let available = memoizedAppointments[i].availableSlots;
      //   let b = Object.keys(available);
      //   if (todaydate === b[0]) {
      //     for (let slots in available) {
      //       let lengthslot = available[slots].slots.length;
      //       if (lengthslot !== 0) {
      //         // console.log("Func true");
      //         return true;
      //       } else {
      //         // console.log("Func false");
      //         return false;
      //       }
      //     }
      //   }
      // }
      for (let i in memoizedAppointments) {
        let available = memoizedAppointments[i].availableSlots;
  
        for (let slots in available) {
          let lengthslot = available[slots].slots.length;
          if (lengthslot !== 0 || lengthslot === 0) {
            // console.log("Func true");
            return true;
          } else {
            // console.log("Func false");
            return false;
          }
        }
      }
    }

    const hasAppointments = memoizedAppointments?.reduce((acc, curr) => {
      return acc || Object.keys(curr.availableSlots);
    }, false);
    // For checking available appointment slote
    // const hasApptSlotAvailable = memoizedAppointments?.some((appt) => {
    //   const slotArr = Object.values(appt?.availableSlots);
    //   const isAvailableAppt = slotArr?.some((el) => el?.slots?.length > 0);
    //   return isAvailableAppt;
    // });

  return (
    <>
      <div className={style.page}>

        {/* <div className={style.App}>
      <div className={style.searchContainer}>
      <input type="text"  className={style.searchBar} placeholder="Enter ZIP code" />
      <button  className={style.locationButton}>Use my current location</button>
      </div>
      <div  className={style.cardsContainer}>
        {centers.map(center => (
          <AvailabilityCard key={center.id} center={center} />
        ))}
      </div>
    </div> */}


        <div className="min-h-screen bg-gray-200 p-8">
          <div className="mx-auto">
            <div className={`${style.parent} mb-8 flex justify-between`}>
              <div className={`${style.reasonVisit}`}>
              <p>Reason for Visit</p>
              <div className={`${style.users_btn}`}>
              <button
                  onClick={() => setApptType("Sick")}
                  className= {`${style.u_btn} ${apptType === 'Sick' ? style.returning_active  : style.u_btn}  py-2 px-4`} 
                >
                  Sick
                </button>
                
                <button className={`${style.u_btn} ${apptType === 'Wellness' ? style.returning_active  : style.u_btn} py-2 px-4`} 
                onClick={() => setApptType("Wellness")}>
                  Wellness
                </button>

              </div>
              </div>
              <div className={`${style.location_center_parent} flex`}>
              <div className="centerParent" ref={ref} >
                <div className="w-auto"><p className={`${style.headingStyle} font-semibold`}>Find centers near</p></div>
                <div className={`${style.dropdownParent}`}>
                <input
                  className={` ${style.inputFix}  md:max-w-xl rounded-lg border-2 border-solid border-gray-400 cus_input w-30 p-4 border border-gray-300 rounded-md `}
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  placeholder="Enter city, state or ZIP"
                  type="search"
                  onKeyUp={event => {
                    if (event.key === 'Enter' && (!isNaN(event.target.value)) && event.target.value.length == 5) {
                      setLocations({ searchAddress: event.target.value });
                      clearSuggestions();
                    }
                  }}
                />
                {status === "OK" && (
                  
              <div className={` ${style.locationDropdown}`} style={{ zIndex: "1" }}>
                <ul
                  className={` ${style.cusDropdown}`}
                  style={{
                    height: "100%",
                    height: "auto",
                    overflowY: "auto",
                    marginBottom: "200px",
                    width: "100%",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  {renderSuggestions()}
                </ul>
              </div>
              
            )}
            </div>
                {/* <input
                  className="w-30 p-4 border border-gray-300 rounded-md"
                /> */}
              </div>
              <div className={`${style.currLocParent} ml-3 mt-10`}>
                <div
                  onClick={() => getCurrentLocationHandler()}
                  className={`${style.use_my_location} flex leading-5 underline`}
                  style={{ cursor: "pointer" }}
                >
                  <img src={currLocationImg} className="mr-2" />Use my Current Location</div>
              </div>
              </div>
            </div>

            {isAppointmentSlotLoading && (
          <div className="book-appoiment-loader" style={{ margin: windowWidth < 768 ? "0px 30px 0px 15px"  :"0px 35px 50px 35px"}}>
            <div className="snippet" style={{ height: "280px"}}>
              <div className="stage" style={{ margin: "0px"}}>
                <p className="paratext" style={{ bottom: "75px"}}>Looking for appointments </p>

                <div className="stage_inner">
                  <div className="dot-spin"></div>
                </div>
              </div>
            </div>
          </div>
        )}

           {!isAppointmentSlotLoading && <div className={`${style.cardsContainer} grid grid-cols-3 gap-8 g-0`}>
              {/* {centers.map(center => ( */}
                <AvailabilityCard 
                  memoizedAppointments = {memoizedAppointments}
                  location = {location}
                  topLocationsStatus={topLocationsStatus}
                  hasTopLocations={hasTopLocations}
                  onClick={(appointment, location) =>
                    setAppointmentSlot({ location, appointment })
                  }
                  selectedAppointmentSlot={selectedAppointmentSlot}
                  selectedLocation={selectedLocation}
                  windowWidth={windowWidth}
                  hasAppointments={hasAppointments}
                  dataAppointmentLocations={appointmentLocations}
                  appointmentType={state.appointmentType}
                  searchAddress= {searchAddress}
                // key={center.id} center={center} 
                />
              {/* ))} */}
            </div>}
          </div>
        </div>
      </div>
      <div className="pagination-container">
        <div className="h-16 fixed flex space-x-4 justify-center items-center left-0 p-4 bottom-0 border-t-2 border-gray-200 border-solid bg-white w-full">
          {/* {paginationState.back.enabled && (
						<Link
							className="is-style-white-button outline-offset-2"
							to={paginationState.back.url}
						>
							<span className="block-style py-2 px-4">
								{"Back"}
								<span className="sr-only"> a Page</span>
							</span>
						</Link>
					)} */}
          {/* <Link to={"/sign-in/retrieve"}>Force Sign In</Link> */}
          <Link

            className={` ${style.greenButton} outline-offset-2
              ${slotLocationSelected? 'opacity-100' : 'cursor-not-allowed opacity-50'}
            `}

            to={slotLocationSelected ? state.userIsAuthed ? routes.authSync : email ? routes.petSelect : routes.signIn : '#'}
            disabled={!slotLocationSelected}
          >
            <span className={`${style.blockStyle} py-2 px-4`} >
              {"Next"}
              <span className="sr-only"> Page</span>
            </span>
          </Link>
        </div>

      </div>
    </>
  );
}
