import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";

import Loader from "./components/generics/Loader.jsx";
import Pagination from "./components/Pagination.jsx";
import Page404 from "./pages/Page404.jsx";
import { ErrorBoundary } from "react-error-boundary";
import PageErrorBoundary from "./components/errors/PageErrorBoundary.jsx";
import routes from "./routes";
import Header from "./Header.jsx";
import Register from "./pages/Register";

// contexts
import { GlobalStateProvider } from "./contexts/GlobalContext/index.jsx";
import { PaginationContextProvider } from "./contexts/PaginationContext/index.jsx";
import RedirectAbandonBooking from "./pages/RedirectAbandonBooking.jsx";

import ReferAFriend from "./pages/ReferAFriend/ReferAFriend.jsx";
import TodaysAppointment from "./pages/TodaysAppointment/todaysAppointment.jsx";

// pages
const AppointmentSelection = React.lazy(() =>
  import("./pages/SelectAppointmentTime/index.jsx")
);
const UserSignIn = React.lazy(() => import("./pages/UserSignIn.jsx"));
// const ReferAFriend = React.lazy(() =>
//   import("./pages/ReferAFriend/ReferAFriend.jsx")
// );
const AuthSync = React.lazy(() => import("./pages/AuthSync.jsx"));
const PetSelect = React.lazy(() => import("./pages/PetSelect/index.jsx"));
const AppointmentTypeSelector = React.lazy(() =>
  import("./pages/AppointmentTypeSelector.jsx")
);
const CreditCardinfo = React.lazy(() => import("./pages/CreditCardinfo.jsx"));
const OwnerInfo = React.lazy(() => import("./pages/OwnerInfo.jsx"));
const FinalizeBooking = React.lazy(() => import("./pages/FinalizeBooking.jsx"));
const BookAppointment = React.lazy(() =>
  import("./pages/BookAppointment/index.jsx")
);
const StyleServices = React.lazy(() => import("./pages/Style/Services.jsx"));

function App() {
  return (
    <GlobalStateProvider>
      <Router>
        <Switch>
          <Route exact path={routes.referAFriend}>
            <ReferAFriend />
          </Route>
          <Route exact path={routes.todaysAppointment}>
            <TodaysAppointment />
          </Route>
          <PaginationContextProvider>
            <Router>
              <Header />
              <div className="max-w-2xl mx-auto px-8 md:flex md:items-center md:justify-center md:min-h-screen big_screens">
                <ErrorBoundary FallbackComponent={PageErrorBoundary}>
                  <React.Suspense fallback={<Loader />}>
                    <Switch>
                      <Route path={routes.selectServices}>
                        <StyleServices />
                      </Route>
                      <Route path={routes.selectAppointment}>
                        <AppointmentSelection />
                      </Route>
                      <Route exact path={routes.signIn}>
                        <UserSignIn />
                      </Route>
                      <Route exact path={routes.register}>
                        <Register />
                      </Route>
                      <Route exact path={routes.authSync}>
                        <AuthSync />
                      </Route>
                      <Route path={routes.petSelect}>
                        <PetSelect />
                      </Route>
                      <Route path={routes.ownerInfo}>
                        <OwnerInfo />
                      </Route>
                      <Route path={routes.CreditCardinfo}>
                        <CreditCardinfo />
                      </Route>
                      <Route path={routes.finalize}>
                        <FinalizeBooking />
                      </Route>
                      <Route path={routes.bookAppointment}>
                        <BookAppointment />
                      </Route>
                      <Route exact path={routes.type}>
                        <AppointmentTypeSelector />
                      </Route>
                      <Route exact path={routes.redirectAbandonBooking}>
                        <RedirectAbandonBooking />
                      </Route>
                      <Route path="*">
                        <Page404 />
                      </Route>
                    </Switch>
                  </React.Suspense>
                </ErrorBoundary>
              </div>
              <Pagination />
            </Router>
          </PaginationContextProvider>
        </Switch>
      </Router>
    </GlobalStateProvider>
  );
}

export default App;
